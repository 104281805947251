import React from "react";
import PropTypes from "prop-types";
import { useEditor, useReadOnly, ReactEditor } from "slate-react";
import { Transforms } from "slate";

import "./CheckListItemElement.scss";

const propTypes = {};

const CheckListItemElement = ({ attributes, children, element }) => {
    const editor = useEditor();
    const readOnly = useReadOnly();
    const { checked } = element;

    return (
        <li {...attributes} className="check-list-item-element">
            <span contentEditable={false} className="checkbox-container">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={(event) => {
                        const path = ReactEditor.findPath(editor, element);
                        Transforms.setNodes(editor, { checked: event.target.checked }, { at: path });
                    }}
                />
            </span>
            <span
                contentEditable={!readOnly}
                suppressContentEditableWarning
                data-checked={checked}
                className="checkbox-list-item-text"
            >
                {children}
            </span>
        </li>
    );
};

CheckListItemElement.propTypes = propTypes;

export default CheckListItemElement;
