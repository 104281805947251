import React from "react";
import "./Leaf.scss";

const Leaf = ({ attributes, children, leaf }) => {
    let leafAttributes = { ...attributes };

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.strikethrough) {
        leafAttributes = { ...leafAttributes, style: { textDecoration: "line-through" } };
    }

    return (
        <span
            {...leafAttributes}
            data-color={leaf.color || null}
            data-background-color={leaf.backgroundColor || null}
        >
            {children}
            {!!leaf.cursor && <span className="cursor"></span>}
        </span>
    );
};

export { Leaf };
