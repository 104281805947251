import React, { useState, useRef, useContext } from "react";
import dynamic from "next/dynamic";
import { ReactEditor, useSlate, useSelected } from "slate-react";
import { Editor, Transforms, Path, Range, Node } from "slate";
import { motion, AnimatePresence } from "framer-motion";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

const CodeEditor = dynamic(() => import("@components/CodeEditor/CodeEditor"));
import CheckListItemElement from "../CheckListItemElement/CheckListItemElement";

import Avatar from "@components/Avatar/Avatar";
import { formatDate } from "@modules/date-time";
import { useWorkspaceUrl } from "@hooks/url-hooks";
import { getDefaultPlaceholderText, isListItemElement } from "../helpers";
import { EditorContext } from "@context/editor";

import "./Element.scss";

const Element = (props) => {
    let { attributes, children, element } = props;
    const editor = useSlate();
    const isSelected = useSelected();
    const [isEditorSelected, setIsEditorSelected] = useState(false);
    const codeBlockRef = useRef();
    const path = ReactEditor.findPath(editor, element);

    attributes = { ...attributes, "data-is-selected": isSelected };

    // TODO: Figure out how to make this play nice with editor operations. Currently each letter change
    // is a new operation so while the undo works it does it letter by letter which isn't ideal
    function updateSlateState(value) {
        const path = getCodeBlockPath();

        Transforms.setNodes(editor, { code: value }, { at: path, match: (n) => Editor.isBlock(editor, n) });
    }

    function updateSlateCodeMode(mode) {
        const path = getCodeBlockPath();

        Transforms.setNodes(editor, { mode }, { at: path, match: (n) => Editor.isBlock(editor, n) });
    }

    function getCodeBlockPath() {
        const textEl = codeBlockRef.current.querySelector("[data-slate-node='text']");
        const textNode = ReactEditor.toSlateNode(editor, textEl);
        const textNodePath = ReactEditor.findPath(editor, textNode);
        const codeBlockPath = Path.parent(textNodePath);

        return codeBlockPath;
    }

    function handleLinkClick(url, email) {
        if (typeof window !== "undefined") {
            if (url) window.open(url, "_blank");
            else if (email) window.location.href = `mailto:${email}`;
        }
    }

    function handleAddBlock() {
        const text = Node.string(element);
        let elementToSelectPath = path;

        // We can click the button without having first focused on the editor
        ReactEditor.focus(editor);

        // If the element already has text than we will insert a new element of the same type below it
        if (text !== "") {
            const nextPath = Path.next(path);
            elementToSelectPath = nextPath;

            Transforms.insertNodes(
                editor,
                {
                    type: isListItemElement(element) ? element.type : "paragraph",
                    children: [{ text: "" }],
                },
                { at: nextPath }
            );
        }

        const leafPath = [...elementToSelectPath, 0];
        const selection = { anchor: { path: leafPath, offset: 0 }, focus: { path: leafPath, offset: 0 } };
        editor.changeModeToCommand(selection);

        // Clicking the button lost our selection so we need to set that again
        Transforms.setSelection(editor, selection);

        Transforms.setNodes(editor, { placeholder: "Type to filter" }, { at: elementToSelectPath });
    }
    // I could probably use attributes to pass information between nodes and events on those nodes
    switch (element.type) {
        case "block-quote":
            return (
                <ElementWidthContainer type={element.type}>
                    <BlockButtonsContainer onAddBlack={handleAddBlock}>
                        <blockquote {...attributes} data-block-quote>
                            {children}
                        </blockquote>
                    </BlockButtonsContainer>
                </ElementWidthContainer>
            );
        case "bulleted-list":
            return (
                <ElementWidthContainer type={element.type}>
                    <ul {...attributes} data-bulleted-list>
                        {children}
                    </ul>
                </ElementWidthContainer>
            );
        case "main-heading": {
            const text = Node.string(element);
            const isVisible = text.length === 0;

            return (
                <ElementWidthContainer type={element.type}>
                    <div className="main-heading-container">
                        <h1
                            {...attributes}
                            data-main-heading
                            data-placeholder-visible={isVisible}
                            data-placeholder-text={
                                element.placeholder || getDefaultPlaceholderText("main-heading")
                            }
                        >
                            {children}
                        </h1>
                        <div className="bookmark-hook" contentEditable={false}></div>
                    </div>
                </ElementWidthContainer>
            );
        }
        case "minor-heading":
            return (
                <ElementWidthContainer type={element.type}>
                    <BlockButtonsContainer onAddBlack={handleAddBlock}>
                        <h2 {...attributes} data-minor-heading>
                            {children}
                        </h2>
                    </BlockButtonsContainer>
                </ElementWidthContainer>
            );
        case "sub-heading":
            return (
                <ElementWidthContainer type={element.type}>
                    <BlockButtonsContainer onAddBlack={handleAddBlock}>
                        <h3 {...attributes} data-sub-heading>
                            {children}
                        </h3>
                    </BlockButtonsContainer>
                </ElementWidthContainer>
            );
        case "list-item":
            return (
                <BlockButtonsContainer onAddBlack={handleAddBlock}>
                    <li {...attributes} data-list-item>
                        {children}
                    </li>
                </BlockButtonsContainer>
            );
        case "numbered-list":
            return (
                <ElementWidthContainer type={element.type}>
                    <ol {...attributes} data-numbered-list>
                        {children}
                    </ol>
                </ElementWidthContainer>
            );
        case "link":
            let href;
            if (element.url) href = element.url;
            else if (element.email) href = `mailto:${element.email}`;
            return (
                <a
                    {...attributes}
                    target={element.url ? "_blank" : null}
                    href={href}
                    rel="noopener noreferrer"
                    onClick={() => handleLinkClick(element.url, element.email)}
                    data-link
                >
                    {children}
                </a>
            );
        case "internal-link":
            const pageUrl = useWorkspaceUrl("page/[page]/edit", `page/${element.pageId}/edit`);
            return (
                <Link href={pageUrl.href} as={pageUrl.as}>
                    <a
                        {...attributes}
                        data-internal-link
                        onClick={() => {
                            // When navigating between pages we need to make sure the selection isn't
                            // set to a node that doesn't exist on the next page. The selection isn't updated
                            // when the content is updated, so if it points to a non-existent node Slate will crash.
                            Transforms.setSelection(editor, {
                                anchor: { path: [0, 0], offset: 0 },
                                focus: { path: [0, 0], offset: 0 },
                            });
                        }}
                    >
                        {children}
                    </a>
                </Link>
            );
        // case "placeholder":
        //     return (
        //         <span
        //             {...attributes}
        //             data-placeholder
        //             contentEditable={false}
        //             data-placeholder-text={element.placeholder}
        //         >
        //             {/* {element.placeholder} */}
        //             {children}
        //         </span>
        //     );
        case "code":
            // We need the children to be rendered otherwise Slate will complain when we try find
            // the code blocks path
            return (
                <ElementWidthContainer type={element.type}>
                    <div {...attributes} data-code-block data-slate-editor ref={codeBlockRef}>
                        <div contentEditable={false}>
                            <CodeEditor
                                value={element.code ?? ""}
                                mode={element.mode}
                                readOnly={ReactEditor.isReadOnly(editor)}
                                onModeChange={updateSlateCodeMode}
                                onChange={updateSlateState}
                                onExit={(escapeRoute) => {
                                    const path = getCodeBlockPath();

                                    if (escapeRoute === "top") {
                                        const newPath = Path.previous(path);
                                        ReactEditor.focus(editor);
                                        Transforms.select(editor, newPath);
                                    } else if (escapeRoute === "bottom") {
                                        const newPath = Path.next(path);
                                        const nodeAtNewPathExists = Node.has(editor, newPath);

                                        if (!nodeAtNewPathExists) {
                                            Transforms.insertNodes(
                                                editor,
                                                { type: "paragraph", children: [{ text: "" }] },
                                                { at: newPath }
                                            );
                                        }

                                        ReactEditor.focus(editor);
                                        Transforms.select(editor, newPath);
                                    }
                                }}
                                onSelectEditor={(isSelected) => setIsEditorSelected(isSelected)}
                                selected={isEditorSelected}
                                onDeleteEditor={() => {
                                    const path = getCodeBlockPath();

                                    Transforms.setNodes(
                                        editor,
                                        { type: "paragraph", children: [{ text: "" }] },
                                        {
                                            match: (n) => Editor.isBlock(editor, n),
                                            at: path,
                                        }
                                    );

                                    ReactEditor.focus(editor);
                                    Transforms.select(editor, path);
                                }}
                            />
                        </div>
                        <div style={{ height: 0, overflow: "hidden" }}>{children}</div>
                    </div>
                </ElementWidthContainer>
            );
        case "check-list":
            return (
                <ElementWidthContainer type={element.type}>
                    <fieldset {...attributes} data-check-list>
                        <ul>{children}</ul>
                    </fieldset>
                </ElementWidthContainer>
            );
        case "check-list-item":
            return (
                <BlockButtonsContainer onAddBlack={handleAddBlock}>
                    <CheckListItemElement {...props} />
                </BlockButtonsContainer>
            );
        case "editor-metadata":
            return <MetadataSection {...props} metadata={element} />;
        default: {
            const text = Node.string(element);
            const isVisible =
                (editor.children.length <= 2 || isSelected) &&
                text.length === 0 &&
                (!editor.selection || (editor.selection && Range.isCollapsed(editor.selection)));

            return (
                <ElementWidthContainer type={element.type}>
                    <BlockButtonsContainer onAddBlack={handleAddBlock}>
                        <p
                            {...attributes}
                            data-paragraph
                            data-id={element.id}
                            data-placeholder-visible={isVisible}
                            data-placeholder-text={
                                element.placeholder || getDefaultPlaceholderText("paragraph")
                            }
                        >
                            {children}
                        </p>
                    </BlockButtonsContainer>
                </ElementWidthContainer>
            );
        }
    }
};

const ElementWidthContainer = ({ size = "normal", children, type }) => (
    <div className="editor-element-center" data-type={type}>
        <div className="editor-element-limit-width" data-size={size}>
            {children}
        </div>
    </div>
);

const BlockButtonsContainer = ({ draggable = true, onAddBlack, children }) => {
    const { isTyping } = useContext(EditorContext);

    return (
        <div className="block-buttons-container" data-show={!isTyping}>
            {children}
            <div className="block-buttons-positioner" contentEditable={false}>
                <button className="add-block-btn" onClick={onAddBlack}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
        </div>
    );
};

const EditorContainer = ({ children }) => (
    <motion.div
        layout
        initial={false}
        whileHover={{
            scale: 1.03,
            boxShadow: "0px 3px 3px rgba(0,0,0,0.15)",
        }}
        whileTap={{
            scale: 1.12,
            boxShadow: "0px 5px 5px rgba(0,0,0,0.1)",
        }}
        drag="y"
        // Animate the component back to 0 when dragging ends
        dragConstraints={{ top: 0, bottom: 0 }}
        // But allow full movement outside those constraints
        dragElastic={1}
    >
        {children}
    </motion.div>
);

// owner, createdAt, status
const MetadataSection = ({ attributes, children, metadata }) => {
    const profileUrl = useWorkspaceUrl("profile/[...slug]", `profile/${metadata?.owner?.id}`);

    const avatarPlaceholder = metadata?.owner?.firstName?.charAt(0)?.toUpperCase();
    const authorFullName = `${metadata?.owner?.firstName} ${metadata?.owner?.lastName}`;
    const editedAt = formatDate(new Date(metadata?.createdAt), { greaterThanPrefix: "Created" });
    const showStatus =
        metadata?.status === "outdated" || metadata?.status === "deleted" || metadata?.status === "archived";

    return (
        // Need contentEditable=false or Firefox has issues with certain input types.

        <ElementWidthContainer>
            <div className="author-section" {...attributes} contentEditable={false}>
                <Link href={profileUrl.href} as={profileUrl.as}>
                    <a className="avatar-container">
                        <Avatar placeholderText={avatarPlaceholder} />
                    </a>
                </Link>
                <div className="author-name">{authorFullName}</div>
                <time className="edited-at">{editedAt}</time>
                <AnimatePresence>
                    {showStatus && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="tablet-status-container"
                        >
                            <span
                                className="tablet-status-badge"
                                data-archived={metadata?.status === "archived"}
                            >
                                {metadata?.status}
                            </span>
                        </motion.div>
                    )}
                </AnimatePresence>
                {children}
            </div>
        </ElementWidthContainer>
    );
};

export { Element };
