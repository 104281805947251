import React, { forwardRef } from "react";
import "./ToolbarButton.scss";

/**
 * A toolbar button that doesn't steal focus from the editor
 */
const ToolbarButton = forwardRef(({ children, active = false, onClick, ariaLabel, ...rest }, ref) => {
    // This component can be used with Tooltips so we need to forward the ref as well as spread the
    // additional props passed from the tooltip. https://github.com/reach/reach-ui/issues/171
    return (
        <button
            role="button"
            aria-label={ariaLabel}
            aria-pressed={active}
            onTouchStart={(e) => e.preventDefault()}
            onMouseDown={(e) => e.preventDefault()}
            onClick={onClick}
            className="toolbar-button"
            data-active={active}
            unselectable="on"
            ref={ref}
            {...rest}
        >
            {children}
        </button>
    );
});

export { ToolbarButton };
