import React, { forwardRef } from "react";
import "./ToolbarListItem.scss";

const ToolbarListItem = forwardRef(({ children, active = false, onClick, ariaLabel, ...rest }, ref) => {
    return (
        <button
            role="button"
            aria-label={ariaLabel}
            onTouchStart={(e) => e.preventDefault()}
            onMouseDown={(e) => e.preventDefault()}
            onClick={onClick}
            className="toolbar-list-item"
            data-active={active}
            unselectable="on"
            ref={ref}
            {...rest}
        >
            {children}
        </button>
    );
});

export { ToolbarListItem };
