import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSlate } from "slate-react";
import { Editor, Range, Path, Text } from "slate";
import { exists } from "@components/ScribeEditor/helpers";
import { throttle, debounce } from "@modules/helpers";
import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";

/**
 * Monitor a specific property on the editor object and if it becomes a particular value
 * then record the current point.
 * @param {string} property The property on the editor we are monitoring
 * @param {function} condition The callback that checks whether the editors property is of a cettain value
 */
export function useStartPoint(property, condition) {
    const editor = useSlate();

    const startPoint = useMemo(() => {
        if (!editor?.selection || !condition(editor[property])) return null;

        return Range.start(editor.selection);
    }, [editor[property]]);

    return startPoint;
}

/**
 * Get the editor text from a particular point to the current selection
 * @param {string} point The Slate point (path and offset) from which we want to start the capture
 */
export function useTextFromPoint(point) {
    const editor = useSlate();
    const [currentNodeText, setCurrentNodeText] = useState("");

    useEffect(() => {
        // It is possible the the point no longer exists. This can happen when we delete the node that contained the point.
        // The specific scenario is if we trigger the command window via the button and press backspace. The node will be deleted
        // and this will run before we have had a chance to update the editor mode and therefore the start point.
        if (!editor?.selection || !point || !exists(editor, point.path)) {
            setCurrentNodeText("");
            return;
        }

        setCurrentNodeText(Editor.string(editor, { anchor: point, focus: editor.selection.anchor }));
    }, [point, editor.selection]);

    return currentNodeText;
}

export function useUnfocusedListControls({
    show,
    highlightedValue,
    listRef,
    optionSelector,
    optionElementSelector,
    onChangeHighlight,
    onSelect,
    deps = [],
}) {
    useEffect(() => {
        if (!show) return;

        function handleKeyDown(event) {
            switch (event.key) {
                case "ArrowDown":
                case "ArrowUp": {
                    if (!listRef.current) return;

                    // Don't scroll the page
                    event.preventDefault();
                    const indexMove = event.key === "ArrowDown" ? 1 : -1;
                    const options = optionSelector(listRef.current);

                    const currentOptionIndex = options.indexOf(highlightedValue);
                    const nextIndex = currentOptionIndex + indexMove;

                    let boundedNextIndex = nextIndex;
                    if (nextIndex < 0) boundedNextIndex = options.length - 1;
                    if (nextIndex > options.length - 1) boundedNextIndex = 0;

                    const nextOptionValue = options[boundedNextIndex];

                    onChangeHighlight(nextOptionValue);

                    break;
                }

                case "Enter": {
                    /* Need this! I had a wierd bug were pressing enter would open up a dialog and because
                            the close button was immediately focused, would also trigger the close button. */
                    event.preventDefault();

                    if (highlightedValue) {
                        onSelect(highlightedValue);
                    }

                    break;
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show, highlightedValue, ...deps]);

    // Because we aren't actually focusing any of the items the scroll position won't change if we
    // highlight an option not in view. So we need to fill this in ourselves with a little logic.
    useIsomorphicLayoutEffect(() => {
        if (!highlightedValue || !listRef.current) return;

        const optionEl = optionElementSelector(listRef.current);

        // This only seems to happen in firefox
        if (!optionEl) return;

        const distanceOutOfView = Math.max(
            0,
            optionEl.offsetTop - listRef.current.clientHeight + optionEl.clientHeight + 8
        );

        listRef.current.scrollTo(0, distanceOutOfView);
    }, [highlightedValue, ...deps]);
}

export function useResponsiveSelectionRect(measure) {
    const [rect, setRect] = useState();

    useEffect(() => {
        if (!measure && rect) setRect(null);
        if (!measure) return;

        function handleRectUpdate() {
            const domSelection = window.getSelection();
            const domRange = domSelection.getRangeAt(0);
            const rect = domRange.getBoundingClientRect();

            setRect(rect);
        }

        const throttledResize = throttle(handleRectUpdate, 100);
        const debouncedScroll = debounce(handleRectUpdate, 100);

        window.addEventListener("resize", throttledResize);
        window.addEventListener("scroll", debouncedScroll);

        // This is the initial measurement, the event listeners are only responsible for updating the
        // measurement if something changes from this point onwards.
        handleRectUpdate();

        return () => {
            window.removeEventListener("resize", throttledResize);
            window.removeEventListener("scroll", debouncedScroll);
        };
    }, [measure]);

    return rect;
}

export function useOnSelectionPathChange(startMonitoring, selection, callback) {
    const selectionOnShowRef = useRef();

    useEffect(() => {
        if (startMonitoring) selectionOnShowRef.current = selection;
    }, [startMonitoring]);

    // You can still move the cursor around using the left and right arrow keys. We want to make sure
    // that as soon as the selection changes whilst the popover is open that we close the popover
    useEffect(() => {
        if (
            startMonitoring &&
            selectionOnShowRef.current &&
            selection &&
            !Path.equals(selectionOnShowRef.current.anchor.path, selection.anchor.path)
        ) {
            callback();
        }
    }, [startMonitoring, selection]);
}
