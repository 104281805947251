import React, { useState, useRef, useEffect } from "react";
import { useSlate, ReactEditor } from "slate-react";
import { Editor, Range, Transforms, Node } from "slate";
import Portal from "@reach/portal";
import Tooltip from "@components/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faLink, faCode } from "@fortawesome/pro-regular-svg-icons";
import isHotkey from "is-hotkey";

import { ToolbarListItem } from "../ToolbarListItem/ToolbarListItem";
import { ToolbarPopover } from "../ToolbarPopover/ToolbarPopover";
import { ToolbarButton } from "../ToolbarButton/ToolbarButton";
import Input, { urlValidator } from "@components/Input/Input";
import SubScript from "@components/SubScript/SubScript";

import {
    isBlockActive,
    toggleFormat,
    toggleBlock,
    isFormatCompletelyActive,
    getSelectedBlockFormat,
    toggleLink,
    isLinkElement,
    getSelectedTextColor,
    getSelectedTextBackgroundColor,
    isColorActive,
    toggleColor,
    toggleBackgroundColor,
    isBackgroundColorActive,
    isMainHeadingSelected,
} from "../helpers";

import "./Toolbar.scss";

const OFFSET_Y = 10;

const isMac = typeof navigator !== "undefined" && navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const ELEMENT_TYPE_MAP = {
    "minor-heading": "Heading",
    "sub-heading": "Small Heading",
    "bulleted-list": "Bulleted List",
    "numbered-list": "Numbered List",
    "check-list": "Check List",
    code: "Code Block",
    paragraph: "Text",
    link: "Link",
    "internal-link": "Connection",
    multiple: "Multiple",
};

const ToolbarTooltipContent = ({ label, command }) => {
    return (
        <div className="toolbar-tooltip-content">
            <div className="tooltip-label">{label}</div>
            <div className="tooltip-command">{command}</div>
        </div>
    );
};

const Toolbar = ({ children }) => {
    const toolbarRef = useRef();
    const blockSelectBtnRef = useRef();
    const linkBtnRef = useRef();
    const colorSelectionBtnRef = useRef();
    const selectionRect = useRef();
    const selectionRef = useRef();
    const linkInputRef = useRef();
    const editor = useSlate();
    const [show, setShow] = useState(false);
    const [showBlockSelect, setShowBlockSelect] = useState(false);
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [textNodeLink, setTextNodeLink] = useState();
    const [linkInvalid, setLinkInvalid] = useState(false);
    const [showColorSelection, setShowColorSelection] = useState(false);

    useEffect(() => {
        const el = toolbarRef.current;
        const { selection } = editor;

        if ((!el || !selection) && !showLinkInput && !showBlockSelect) {
            setShow(false);
            selectionRef.current = null;
            return;
        }

        if (
            isMainHeadingSelected(editor) ||
            !ReactEditor.isFocused(editor) ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ""
        ) {
            // TODO: In firefox if you open and close the block select it will close the entire toolbar.
            // This happens because document.activeElement is set to the body where in chrome it's set to
            // the toolbar button that was clicked to open it. It's not a big deal but it would be nice to
            // make the behaviour the same
            if (!el.contains(document.activeElement) && !showLinkInput && !showBlockSelect) {
                setShow(false);
                selectionRef.current = null;
            }
            return;
        }

        selectionRef.current = selection;

        const domSelection = window.getSelection();
        const domRange = domSelection.getRangeAt(0);

        // We can't just use the domRange itself when calculating the clientRect because it will give shitty
        // results when you cover multiple blocks (the position will be wrong). Instead we need to calculate the
        // position ourselves by looking at all the clientRects encapsulated within our selection and grabbing the
        // top leftmost rect
        const rects = domRange.getClientRects();
        const rectToPin = [...rects].reduce((rectToPin, rect) => {
            // This comes first because we don't want to include any rects with 0 width and these can come first
            if (rect.width === 0) return rectToPin;
            if (!rectToPin) return rect;
            if (rect.y < rectToPin.y) return rect;
            if (rect.y === rectToPin.y && rect.x < rectToPin.x) return rect;
            else return rectToPin;
        }, null);

        selectionRect.current = rectToPin;
    });

    useEffect(() => {
        function handleMouseUp(event) {
            if (!selectionRect.current || show) return;
            const el = toolbarRef.current;

            const topPos = Math.round(
                selectionRect.current.top + window.pageYOffset - el.offsetHeight - OFFSET_Y
            );
            const leftPos = Math.round(selectionRect.current.left + window.pageXOffset - 20);

            el.style.top = `${topPos}px`;
            el.style.left = `${leftPos}px`;

            setShow(true);
        }

        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [show]);

    useEffect(() => {
        function handleKeyUp(event) {
            if (event.key === "Escape" && show) {
                event.stopPropagation();
                selectionRect.current = null;
                setShow(false);
            } else if (
                selectionRect.current &&
                !show &&
                editor.selection &&
                !Range.isCollapsed(editor.selection)
            ) {
                const el = toolbarRef.current;

                const topPos = Math.round(
                    selectionRect.current.top + window.pageYOffset - el.offsetHeight - OFFSET_Y
                );
                const leftPos = Math.round(selectionRect.current.left + window.pageXOffset - 20);

                el.style.top = `${topPos}px`;
                el.style.left = `${leftPos}px`;

                setShow(true);
            }
        }

        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, [show]);

    useEffect(() => {
        if (!show) return;

        function handleKeyDown(event) {
            if (isHotkey("mod+k", event)) {
                handleOpenLinkPopover();
            }
            if (isHotkey("mod+shift+h", event)) {
                // It takes you back to your default page if you don't cancel it
                event.preventDefault();
                handleOpenColorSelection();
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [show]);

    function handleToggleBlock(format) {
        toggleBlock(editor, format);
        setShowBlockSelect(false);
    }

    function handleToggleColor(color) {
        toggleColor(editor, color);
        setShowColorSelection(false);
    }

    function handleToggleBackgroundColor(color) {
        toggleBackgroundColor(editor, color);
        setShowColorSelection(false);
    }

    function handleInputPopoverClose() {
        ReactEditor.focus(editor);
        Transforms.select(editor, selectionRef.current);
        setShowLinkInput(false);
    }

    function handleFormatChange(format) {
        toggleFormat(editor, format);

        /* Slate makes sure that the DOM selection stays in sync with the editors selection (Editable, Line 133). 
        However this doesn't take effect unless the Editor is focused. Unfortunately whenever we click a button in
        the toolbar the editor loses focus, but it doesn't lose its selection (why this is the case I'm not really
        sure). So all we have to do is refocus the editor and Slate will sync the DOM selection state for us. */
        ReactEditor.focus(editor);
    }

    function handleLinking(event) {
        event.preventDefault();
        handleInputPopoverClose();
        toggleLink(editor, textNodeLink);
        setTextNodeLink("");
    }

    function handleUnlinking(event) {
        handleInputPopoverClose();
        toggleLink(editor, textNodeLink);
        setTextNodeLink("");
    }

    function handleOpenLinkPopover() {
        const [linkNode] =
            Editor.above(editor, {
                match: (n) => isLinkElement(n),
                mode: "lowest",
            }) || [];

        if (linkNode) setTextNodeLink(linkNode.url);
        setShowLinkInput(true);
    }

    function handleColorPopoverClose() {
        setShowColorSelection(false);
    }

    function handleOpenColorSelection() {
        setShowColorSelection(true);
    }

    useEffect(() => {
        if (showLinkInput && linkInputRef.current) {
            linkInputRef.current.focus();
        }
    }, [showLinkInput]);

    const currentSelection = editor.selection || selectionRef.current;
    const selectedBlockFormat = getSelectedBlockFormat(editor, currentSelection);

    return (
        <Portal>
            <div className="toolbar" ref={toolbarRef} data-show={show}>
                <ToolbarPopover
                    show={showColorSelection}
                    onClose={handleColorPopoverClose}
                    pinTo={colorSelectionBtnRef}
                    interactive
                >
                    <div className="toolbar-list-container">
                        <div className="toolbar-subheading">Change color to</div>

                        <ToolbarColorListItem editor={editor} onSelect={handleToggleColor} />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="grey"
                            colorName="Grey"
                            onSelect={handleToggleColor}
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="brown"
                            colorName="Brown"
                            onSelect={handleToggleColor}
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="orange"
                            colorName="Orange"
                            onSelect={handleToggleColor}
                        />
                        {/* <ToolbarColorListItem
                            editor={editor}
                            colorCode="yellow"
                            colorName="Yellow"
                            onSelect={handleToggleColor}
                        /> */}
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="green"
                            colorName="Green"
                            onSelect={handleToggleColor}
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="blue"
                            colorName="Blue"
                            onSelect={handleToggleColor}
                        />
                        {/* <ToolbarColorListItem
                            editor={editor}
                            colorCode="purple"
                            colorName="Purple"
                            onSelect={handleToggleColor}
                        /> */}
                        {/* <ToolbarColorListItem
                            editor={editor}
                            colorCode="pink"
                            colorName="Pink"
                            onSelect={handleToggleColor}
                        /> */}
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="red"
                            colorName="Red"
                            onSelect={handleToggleColor}
                        />

                        <div className="toolbar-subheading">Change background color to</div>
                        <ToolbarColorListItem
                            editor={editor}
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="grey"
                            colorName="Grey"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        {/* <ToolbarColorListItem
                            editor={editor}
                            colorCode="brown"
                            colorName="Brown"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="orange"
                            colorName="Orange"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="yellow"
                            colorName="Yellow"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        /> */}
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="green"
                            colorName="Green"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="blue"
                            colorName="Blue"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        {/* <ToolbarColorListItem
                            editor={editor}
                            colorCode="purple"
                            colorName="Purple"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="pink"
                            colorName="Pink"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        /> */}
                        <ToolbarColorListItem
                            editor={editor}
                            colorCode="red"
                            colorName="Red"
                            onSelect={handleToggleBackgroundColor}
                            isBackground
                        />
                    </div>
                </ToolbarPopover>
                <ToolbarPopover
                    show={showLinkInput}
                    onClose={handleInputPopoverClose}
                    pinTo={linkBtnRef}
                    interactive
                >
                    <form className="toolbar-url-link-container" onSubmit={handleLinking}>
                        <Input
                            placeholder="Enter URL"
                            fixed
                            value={textNodeLink}
                            onChange={(event) => setTextNodeLink(event.target.value)}
                            type="url"
                            ref={linkInputRef}
                            contained={true}
                            validators={[urlValidator]}
                            showErrorMessage={false}
                            onError={(isError) => setLinkInvalid(isError)}
                        />

                        <div className="toolbar-url-link-btns-container">
                            <button className="toolbar-url-link-btn" disabled={linkInvalid}>
                                Link
                            </button>
                            <button
                                className="toolbar-url-link-btn danger"
                                disabled={!isBlockActive(editor, "link", currentSelection)}
                                onClick={handleUnlinking}
                            >
                                Unlink
                            </button>
                        </div>
                    </form>
                </ToolbarPopover>
                <ToolbarPopover
                    show={showBlockSelect}
                    onClose={() => setShowBlockSelect(false)}
                    pinTo={blockSelectBtnRef}
                >
                    <div className="toolbar-list-container">
                        <div className="toolbar-heading">Turn into</div>

                        <ToolbarListItem
                            onClick={() => handleToggleBlock("paragraph")}
                            active={isBlockActive(editor, "paragraph")}
                        >
                            <div className="rounded-border classic-text">T</div>

                            <span>{ELEMENT_TYPE_MAP["paragraph"]}</span>
                        </ToolbarListItem>
                        <ToolbarListItem
                            onClick={() => handleToggleBlock("minor-heading")}
                            active={isBlockActive(editor, "minor-heading")}
                        >
                            <div className="rounded-border">
                                <SubScript subscript="1">H</SubScript>
                            </div>

                            <span>{ELEMENT_TYPE_MAP["minor-heading"]}</span>
                        </ToolbarListItem>
                        <ToolbarListItem
                            onClick={() => handleToggleBlock("sub-heading")}
                            active={isBlockActive(editor, "sub-heading")}
                        >
                            <div className="rounded-border">
                                <SubScript subscript="2">H</SubScript>
                            </div>

                            <span>{ELEMENT_TYPE_MAP["sub-heading"]}</span>
                        </ToolbarListItem>
                        <ToolbarListItem
                            onClick={() => handleToggleBlock("bulleted-list")}
                            active={isBlockActive(editor, "bulleted-list")}
                        >
                            <div className="rounded-border">•</div>
                            <span>{ELEMENT_TYPE_MAP["bulleted-list"]}</span>
                        </ToolbarListItem>
                        <ToolbarListItem
                            onClick={() => handleToggleBlock("numbered-list")}
                            active={isBlockActive(editor, "numbered-list")}
                        >
                            <div className="rounded-border">1.</div>
                            <span>{ELEMENT_TYPE_MAP["numbered-list"]}</span>
                        </ToolbarListItem>
                        <ToolbarListItem
                            onClick={() => handleToggleBlock("check-list")}
                            active={isBlockActive(editor, "check-list")}
                        >
                            <div className="rounded-border">✓</div>
                            <span>{ELEMENT_TYPE_MAP["check-list"]}</span>
                        </ToolbarListItem>
                        {/* <ToolbarListItem
                            onClick={() => handleToggleBlock("code")}
                            active={isBlockActive(editor, "code")}
                        >
                            <div className="rounded-border">
                                <FontAwesomeIcon icon={faCode} />
                            </div>
                            <span>{ELEMENT_TYPE_MAP["code"]}</span>
                        </ToolbarListItem> */}
                    </div>
                </ToolbarPopover>
                <Tooltip label={<ToolbarTooltipContent label="Turn Into" />}>
                    <ToolbarButton
                        onClick={() => setShowBlockSelect(true)}
                        onMouseDown={(e) => e.preventDefault()}
                        ref={blockSelectBtnRef}
                        ariaLabel="Change text type"
                    >
                        <div className="select-block-type-content">
                            <span>{ELEMENT_TYPE_MAP[selectedBlockFormat]}</span>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </div>
                    </ToolbarButton>
                </Tooltip>
                <Tooltip label={<ToolbarTooltipContent label="Bold" command={isMac ? "⌘+B" : "Ctrl+B"} />}>
                    <ToolbarButton
                        active={isFormatCompletelyActive(editor, "bold")}
                        onClick={() => handleFormatChange("bold")}
                        onMouseDown={(e) => e.preventDefault()}
                        ariaLabel="Bold"
                    >
                        B
                    </ToolbarButton>
                </Tooltip>
                <Tooltip
                    label={<ToolbarTooltipContent label="Italicize" command={isMac ? "⌘+I" : "Ctrl+I"} />}
                >
                    <ToolbarButton
                        active={isFormatCompletelyActive(editor, "italic")}
                        onClick={() => handleFormatChange("italic")}
                        onMouseDown={(e) => e.preventDefault()}
                        ariaLabel="Italic"
                    >
                        i
                    </ToolbarButton>
                </Tooltip>

                <Tooltip
                    label={
                        <ToolbarTooltipContent
                            label="Strike-through"
                            command={isMac ? "⌘+Shift+S" : "Ctrl+Shift+S"}
                        />
                    }
                >
                    <ToolbarButton
                        active={isFormatCompletelyActive(editor, "strikethrough")}
                        onClick={() => handleFormatChange("strikethrough")}
                        onMouseDown={(e) => e.preventDefault()}
                        ariaLabel="Strike through"
                    >
                        <span style={{ textDecoration: "line-through" }}>S</span>
                    </ToolbarButton>
                </Tooltip>

                <Tooltip
                    label={<ToolbarTooltipContent label="Add Link" command={isMac ? "⌘+K" : "Ctrl+K"} />}
                >
                    <ToolbarButton
                        onClick={handleOpenLinkPopover}
                        active={isBlockActive(editor, "link", currentSelection)}
                        onMouseDown={(e) => e.preventDefault()}
                        ref={linkBtnRef}
                        ariaLabel="Add Link"
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </ToolbarButton>
                </Tooltip>
                <Tooltip
                    label={
                        <ToolbarTooltipContent
                            label="Text Color"
                            command={isMac ? "⌘+Shift+H" : "Ctrl+Shift+H"}
                        />
                    }
                >
                    <ToolbarButton
                        onClick={handleOpenColorSelection}
                        onMouseDown={(e) => e.preventDefault()}
                        ref={colorSelectionBtnRef}
                        ariaLabel="Text Color"
                    >
                        <div
                            className="select-block-color"
                            data-color={getSelectedTextColor(editor, currentSelection)}
                            data-background-color={getSelectedTextBackgroundColor(editor, currentSelection)}
                        >
                            <span>A</span>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </div>
                    </ToolbarButton>
                </Tooltip>
            </div>
        </Portal>
    );
};

const ToolbarColorListItem = ({
    editor,
    colorCode = "default",
    colorName = "Default",
    isBackground = false,
    onSelect,
}) => {
    function isActive() {
        if (isBackground) {
            return isBackgroundColorActive(editor, colorCode);
        } else {
            return isColorActive(editor, colorCode);
        }
    }

    const colorAttribute = isBackground
        ? { "data-background-color": colorCode }
        : { "data-color": colorCode };
    return (
        <ToolbarListItem onClick={() => onSelect(colorCode)} active={isActive()}>
            <div className="rounded-border classic-text color-icon" {...colorAttribute}>
                S
            </div>
            <span>{colorName}</span>
        </ToolbarListItem>
    );
};

export { Toolbar };
