import { ReactEditor } from "slate-react";
import { Editor, Transforms, Range, Point, Path, createEditor, Element, Text, Node } from "slate";
import { generateEntityId } from "@modules/helpers";
import { isUrl, isEmail } from "@modules/string-filters";
import {
    isListElement,
    isListItemElement,
    canLiftListItem,
    liftListItem,
    getParentListElement,
    isValidListItemForParent,
    toggleLink,
    toggleMark,
    toggleBlock,
    isMainHeadingSelected,
    isPlaceholderElement,
    getNodeText,
    unwrapLink,
    exists,
    isBlockActive,
} from "./helpers";

const MD_SHORTCUTS = {
    "*": "bulleted-list",
    "-": "bulleted-list",
    "1.": "numbered-list",
    ">": "block-quote",
    "#": "minor-heading",
    "##": "sub-heading",
    // "###": "heading-three",
    "```": "code",
    "[]": "check-list",
    "[x]": "check-list",
};

const GEN_SHORTCUTS = {
    "[[": "internal-link",
};

const EFFECTS = {
    "[x]": "checked",
};

const COMMAND_ACTION_MAP = {
    "internal-link": "internal-link",
    "block-reference": "block-reference",
    "strike-through": "format",
    "minor-heading": "block",
    "sub-heading": "block",
    "bulleted-list": "block",
    "numbered-list": "block",
    "check-list": "block",
    code: "block",
    paragraph: "block",
    bold: "format",
    italic: "format",
};

export const MODES = {
    DEFAULT: "normal",
    COMMAND: "command",
    LINK: "link",
};

// TODO: Mobile chrome has multiple bugs in edit mode, e.g. can create multiple headings, lists are super buggy, ect

export function withCode(editor) {
    const { isVoid } = editor;

    editor.isVoid = (element) => {
        return element.type === "code" ? true : isVoid(element);
    };

    return editor;
}

export function withMetadata(editor) {
    const { isVoid } = editor;

    editor.isVoid = (element) => {
        return element.type === "editor-metadata" ? true : isVoid(element);
    };

    return editor;
}

export function withModes(editor) {
    editor.mode = MODES.DEFAULT;
    const commandSelectionRef = { current: null };

    editor.changeMode = (newMode) => {
        editor.mode = newMode;
    };

    editor.changeModeToLink = () => {
        if (editor.mode === MODES.LINK) return;

        editor.mode = MODES.LINK;
        removePlaceholderText();
    };

    editor.changeModeToCommand = (selection) => {
        if (editor.mode === MODES.COMMAND) return;

        editor.mode = MODES.COMMAND;
        commandSelectionRef.current = selection;
    };

    editor.changeModeToDefault = () => {
        // This is really important - by setting the mode to default we can trigger effects in components (e.g. the
        // command popover) that will call this method again and we will end up in an infinite loop. This just prevents
        // that loop.
        if (editor.mode === MODES.DEFAULT) return;

        editor.mode = MODES.DEFAULT;
        removePlaceholderText();
    };

    // When entering command mode via the button to the left of the block we replace the placeholder
    // text with something more helpful - we need to be sure to set this back when we are done.
    function removePlaceholderText() {
        if (commandSelectionRef.current) {
            // It is possible to trigger a mode change via a node deletion. In that case the selectionRef may point
            // to something that no longer exists. If it doesn't exist it is possible that the node deletion actually
            // just triggered a transform (e.g. delete the last list item turns it into a paragraph) and so in that case
            // we should remove the placeholder from that element. I think there will be an oppurtunity to make this nicer
            // by using Slate pointRefs in the future (it updates the ref after every operation so we wouldn't have to worry
            // about the node not existing) however this seems to work fine for now.
            if (exists(editor, commandSelectionRef.current.anchor.path)) {
                Transforms.setNodes(
                    editor,
                    { placeholder: null },
                    { at: commandSelectionRef.current, mode: "all" }
                );
            } else {
                Transforms.setNodes(editor, { placeholder: null }, { at: editor.selection, mode: "all" });
            }
            commandSelectionRef.current = null;
        }
    }

    return editor;
}

export function withInternalLinks(editor) {
    const { insertText, deleteBackward, apply, isInline, insertBreak, normalizeNode } = editor;
    let startPoint;
    let handleRemoveRef = { current: null };

    editor.insertText = (text) => {
        const { selection } = editor;

        // If we are at the start of a node the point will be at the end of the previous node (but after
        // the last character so it's not a problem)
        const prevCharacterPoint = Editor.before(editor, selection, { unit: "character", distance: 1 });
        const range = { anchor: prevCharacterPoint || selection.focus, focus: selection.focus };
        const prevCharacter = Editor.string(editor, range);

        if (
            text === "[" &&
            prevCharacter === "[" &&
            !isMainHeadingSelected(editor) &&
            editor.mode !== MODES.LINK
        ) {
            editor.changeModeToLink();
            startPoint = prevCharacterPoint;
        }

        insertText(text);
    };

    // We normalise because we want to delete any links with no text content. This is crucial to allowing
    // us to detect when a link is deleted and from that remove the connection between the linked and current
    // pages. Without this the user can think they have deleted the link but an empty node would still remain
    // and the pages would be disconnected.
    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (node?.type === "internal-link") {
            const text = Editor.string(editor, path);

            if (text === "") {
                Transforms.delete(editor, { at: path });
            }
        }

        normalizeNode(entry);
    };

    editor.apply = (operation) => {
        const { selection } = editor;

        // This is needed to track programmatic changes to the editors content, which may happen if a user
        // invokes some shortcut or demand, for example the internal-link command will add "[[" to the editor
        // when clicked
        if (operation.type === "insert_text" && operation.text === "[[" && !isMainHeadingSelected(editor)) {
            editor.changeModeToLink();
            startPoint = Range.start(selection);
        }

        // We want to detect when the node is removed, so we can alert the editor to disconnect the relevant pages
        if (operation.type === "remove_node" && operation.node.type === "internal-link") {
            if (handleRemoveRef.current && typeof handleRemoveRef.current === "function") {
                handleRemoveRef.current(operation.node);
            }
        } else if (operation.type === "merge_node") {
            const [element] =
                Editor.nodes(editor, { at: operation.path, match: (n) => n.type === "internal-link" }) || [];
            const [node] = element || [];
            if (node?.type === "internal-link") {
                if (handleRemoveRef.current && typeof handleRemoveRef.current === "function") {
                    handleRemoveRef.current(node);
                }
            }
        }

        apply(operation);
    };

    editor.createInternalLink = (pageId, title, onRemove) => {
        /* We can't just assume that the square brackets were used at the beginning of the text node, we can't
            even assume it was used at the end. It can be used anywhere, even in the middle of a word.
            This means we need to track the point at which the user typed "[[" and the text that may have
            followed - up until the pressed Enter. We can then calculate the range to remove. */
        const rangeToDelete = {
            anchor: {
                path: startPoint.path,
                offset: startPoint.offset,
            },
            focus: editor.selection.anchor,
        };

        // You need to insert the link before deleting. If you don't and it's an empty node slate will delete
        // it after realising there is no text in the node
        const internalLink = { type: "internal-link", pageId, children: [{ text: title }] };
        Transforms.insertNodes(editor, internalLink);
        Transforms.delete(editor, { at: rangeToDelete });

        startPoint = null;
        handleRemoveRef.current = onRemove;
    };

    editor.isInline = (element) => {
        return element.type === "internal-link" ? true : isInline(element);
    };

    // This probably isn't the best way to achieve this. Essentially when we have an internal-link
    // that is an inline element. If you insert a break behind the scene that splits the node. This will
    // work its way up from the text node more or less splitting the node and then copying it's parents.
    // Usually this is fine but for internal links we need to avoid this for multiple reasons. We don't
    // want the ability to split an internal link and create two links (not expected or useful behaviour).
    // We also don't want to run into the situation whereby we press enter whilst we have the final point in
    // the internal link text need selected (i.e. our cursor is at the end of the link). This will create a new
    // node that has an empty internal link which is entirely useless. I could have fixed this with normalisation
    // logic but because I wanted to prevent breaking an internal link in any way it felt better to do it
    // by extending insertBreak.
    editor.insertBreak = () => {
        const [linkMatch] = Editor.nodes(editor, {
            mode: "lowest",
            match: (n) => n.type === "internal-link",
        });

        const [blockMatch] = Editor.nodes(editor, {
            mode: "lowest",
            match: (n) => Editor.isBlock(editor, n),
        });

        if (linkMatch && blockMatch) {
            const [node, path] = blockMatch;
            const freshNode = { ...node, children: [{ text: "" }] };

            const parentPath = path.slice(0, -1);
            const [siblingPath] = path.slice(-1);
            const newPath = parentPath.length > 0 ? [...parentPath, siblingPath + 1] : [siblingPath + 1];

            Transforms.insertNodes(editor, freshNode, { at: newPath });
            Transforms.select(editor, newPath);

            return;
        } else {
            insertBreak();
        }
    };

    return editor;
}

export function withInsertCommand(editor) {
    const { insertText, deleteBackward, changeModeToCommand } = editor;
    let startPoint;

    editor.changeModeToCommand = (selection) => {
        startPoint = Range.start(selection);
        changeModeToCommand(selection);
    };

    editor.insertText = (text) => {
        const { selection } = editor;

        /* We apply a transform if we find that a space has been entered and the text before the space
        in that same block matches one of our shortcuts. The anchor path check is so we don't apply any
        transformations to the main heading */
        if (text === "/" && !isMainHeadingSelected(editor) && editor.mode !== MODES.COMMAND) {
            const prevCharacterPoint = Editor.before(editor, selection, { unit: "character", distance: 1 });
            const range = { anchor: prevCharacterPoint || selection.focus, focus: selection.focus };
            const prevCharacter = Editor.string(editor, range);

            if (prevCharacter === "" || prevCharacter === " ") {
                editor.mode = MODES.COMMAND;
                startPoint = Range.start(selection);
            }
        }

        insertText(text);
    };

    editor.deleteBackward = (unit) => {
        const { selection } = editor;

        if (unit === "character") {
            const characterRange = {
                anchor: {
                    path: selection.anchor.path,
                    offset: Math.max(selection.anchor.offset - 1, 0),
                },
                focus: selection.focus,
            };

            const characterDeleted = Editor.string(editor, characterRange);
            if (characterDeleted === "/") {
                editor.changeModeToDefault();
            }
        }

        deleteBackward(unit);
    };

    editor.insertCommand = (command) => {
        const actionType = COMMAND_ACTION_MAP[command];

        if (!actionType) throw new Error("Command not found in editor");

        // We won't have a start point if the command popover wasn't triggered by typing / (i.e. we
        // triggered it using the add block button)
        if (startPoint) {
            /* We can't just assume that the slash was used at the beginning of the text node, we can't
            even assume it was used at the end. It can be used anywhere, even in the middle of a word.
            This means we need to track the point at which the user typed "/" and the text that may have
            followed - up until the pressed Enter. We can then calculate the range to remove. */
            const rangeToDelete = {
                anchor: {
                    path: startPoint.path,
                    offset: startPoint.offset,
                },
                focus: editor.selection.anchor,
            };

            const nodeText = Editor.string(editor, rangeToDelete);

            // When triggered without typing / this text will be "". If we delete the range we will end up deleting the
            // node which we don't want
            if (nodeText !== "") {
                Transforms.delete(editor, { at: rangeToDelete });
            }
        }

        if (actionType === "block") {
            if (command === "code" && Editor.string(editor, editor.selection.anchor.path) !== "") {
                Transforms.insertNodes(editor, { type: "code", children: [{ text: "" }] });
            } else {
                toggleBlock(editor, command);
            }
        } else if (actionType === "format") {
            toggleMark(editor, command);
        } else if (actionType === "internal-link") {
            Transforms.insertText(editor, "[[");
        } else if (actionType === "block-reference") {
        }
        startPoint = null;
    };

    return editor;
}

export function withUrlLinks(editor) {
    const { insertBreak, insertText, insertData, isInline } = editor;

    // MODIFYING THE UNDO REDO STATE IS REALLY HARD BECAUSE IT WORKS WITH LOW LEVEL OPERATIONS. LEAVE TIL LATER
    // editor.undo = () => {
    //     const { history } = editor;
    //     const { undos } = history;

    //     const batch = undos[undos.length - 1];
    //     console.log(batch);

    //     const insertLinkOp = batch?.find(
    //         (operation) => operation.type === "insert_node" && operation.node.type === "link"
    //     );
    //     if (insertLinkOp) {
    //         const { path } = insertLinkOp;
    //         const [textLinkNode, textLinkPath] = Editor.last(editor, path);
    //         const textLinkRef = Editor.pathRef(editor, textLinkPath, { affinity: "forward" });
    //         unwrapLink(editor, path);

    //         const textRange = Editor.range(editor, textLinkRef.current);
    //         Transforms.select(editor, textRange);

    //         history.redos.push(batch);
    //         history.undos.pop();
    //         textLinkRef.unref();
    //     } else {
    //         undo();
    //     }
    // };

    // editor.redo = () => {
    //     const { history } = editor;
    //     const { undos } = history;
    // }

    function findAndCreateLink(editor) {
        const { selection } = editor;

        /* We apply a transform if we find that a space has been entered and the text before the space
        in that same block matches one of our shortcuts. The anchor path check is so we don't apply any
        transformations to the main heading */

        const { anchor } = selection;
        let nodeText = "";

        const parentMatch = Editor.above(editor, {
            match: (n) => Editor.isBlock(editor, n),
            mode: "lowest",
            at: selection,
        });

        const [parentNode, parentPath] = parentMatch || [];

        if (!parentPath) return;

        const textInfo = [];
        let textNodeIndex = 0;
        let finalTextNodeOffset = 0;
        let startTextNodeOffset = 0;

        for (const [node, path] of Node.texts(editor, {
            from: anchor.path,
            to: parentPath,
            reverse: true,
        })) {
            const text = Editor.string(editor, path);
            const lastTextNode = textNodeIndex === 0;

            let textFound = false;
            let textAfterWhitespace = "";
            let stringToAnalyze = text.split("");

            if (lastTextNode) {
                finalTextNodeOffset = anchor.offset;
                startTextNodeOffset = anchor.offset;
                stringToAnalyze = text.split("").slice(0, anchor.offset);
            } else if (stringToAnalyze[stringToAnalyze.length - 1] !== " ") {
                // I only need to calculate the start text node offset on the final loop (which is the
                // left-most text node) but since that's the last iteration to run it's fine to just do it
                // on every loop and overwrite. This check is to catch an edge case where the final character
                // of the node we are now checking is a space, i.e. [xcs ][goodtext] where [] is a text node.
                // In this case we would have overriden the offset with the length of this text node and then
                // exited, so we only take the offset if we are on the right most node or the final character is
                // not a space.
                startTextNodeOffset = text.length;
            }

            let finishedString = false;

            // We are working our way from the rightmost text (where our selection was) to the first child
            // text node of the parent. The text however is still left to right so we need to reverse it.
            for (const char of stringToAnalyze.reverse()) {
                if (char !== " " && !textFound) textFound = true;
                if (char === " " && !textFound && lastTextNode) finalTextNodeOffset--;

                // We want to discount the first white space we find on the first node we visit because
                // it doesn't tell us anything about the text itself (so we don't know if it's a URL). After
                // we encounter text then we start looking for a whitespace which is our cutoff.
                if (char === " " && (textFound || !lastTextNode)) {
                    finishedString = true;
                    break;
                } else if (char !== " ") {
                    textAfterWhitespace = char + textAfterWhitespace;
                }
                startTextNodeOffset--;
            }

            if (textAfterWhitespace !== "") {
                nodeText = textAfterWhitespace + nodeText;
                textInfo.unshift({ path, text: textAfterWhitespace });
            }

            textNodeIndex++;
            if (finishedString) break;
        }

        if (isUrl(nodeText) || isEmail(nodeText)) {
            /* Now we know that we are dealing with a URL we need to create a Range that we can transform.
                To do this we need to use the first and last elements in our textInfo which tell us the path
                of the text node as well as the relevant text which we can use to calculate offsets. */
            const anchor = {
                path: textInfo[0].path,
                offset: startTextNodeOffset,
            };
            const focus = {
                path: textInfo[textInfo.length - 1].path,
                offset: finalTextNodeOffset,
            };

            const range = { anchor, focus };
            let link;

            if (isUrl(nodeText)) {
                link = { type: "link", url: nodeText, children: [] };
            } else if (isEmail(nodeText)) {
                link = { type: "link", email: nodeText, children: [] };
            }

            Transforms.wrapNodes(editor, link, { match: (n) => Text.isText(n), split: true, at: range });
        }
    }

    editor.insertBreak = () => {
        // TIDY THIS UP FOR URL AND INTERNAL LINKS - PROBABLY NEEDS TO BE IN NORMALISATION LOGIC
        const [linkMatch] = Editor.nodes(editor, {
            mode: "lowest",
            match: (n) => n.type === "link",
        });

        const [blockMatch] = Editor.nodes(editor, {
            mode: "lowest",
            match: (n) => Editor.isBlock(editor, n),
        });

        if (linkMatch && blockMatch) {
            const [node, path] = blockMatch;
            const freshNode = { ...node, children: [{ text: "" }] };

            const parentPath = path.slice(0, -1);
            const [siblingPath] = path.slice(-1);
            const newPath = parentPath.length > 0 ? [...parentPath, siblingPath + 1] : [siblingPath + 1];

            Transforms.insertNodes(editor, freshNode, { at: newPath });
            Transforms.select(editor, newPath);

            return;
        }
        findAndCreateLink(editor);
        insertBreak();
    };

    editor.insertText = (text) => {
        const { selection } = editor;

        /* We apply a transform if we find that a space has been entered and the text before the space
        in that same block matches one of our shortcuts. The anchor path check is so we don't apply any
        transformations to the main heading */
        if (text === " " && !isMainHeadingSelected(editor) && !isBlockActive(editor, "link")) {
            findAndCreateLink(editor);
        }

        insertText(text);
    };

    editor.insertData = (data) => {
        const text = data.getData("text/plain");

        if (text && (isUrl(text) || isEmail(text))) {
            toggleLink(editor, text);
        } else {
            insertData(data);
        }
    };

    editor.isInline = (element) => {
        return element.type === "link" ? true : isInline(element);
    };

    return editor;
}

/*
DOCUMENT: Another approach would have been to use onDOMBeforeInput and watch for for insertLinkBreak.
From here I would have then had to reimplement the behaviour of Editor.insertBreak() but changing the
format of the newly created element as intended. This is a lot of work/code. It's better to let insertBreak
do it's thing of intelligently splitting both element and text nodes. Then all I need to do is alter its
result properties.
*/
export function withSmartFormatting(editor) {
    const { insertBreak, insertData } = editor;

    let shiftEnterPressed = false;

    if (typeof window !== "undefined") {
        window.addEventListener("keypress", (evt) => {
            if (evt.keyCode == 13 && evt.shiftKey) {
                shiftEnterPressed = true;
            }
        });
    }

    editor.insertBreak = () => {
        /* Insert break essentially splits the current node so what we have to do is get the newly
        created node and change it's properties to paragraph instead of whatever it is currently if
        we determine that it
        */

        // Don't insert a new paragraph after the main heading if an empty paragraph already exists
        const [mainHeadingNode] = Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n) && n.type === "main-heading",
            mode: "highest",
        });

        const nodeAfterHeadingExists = Node.has(editor, [1, 0]);

        if (nodeAfterHeadingExists && mainHeadingNode) {
            const [match] = Editor.nodes(editor, {
                match: (n) => n.type === "paragraph",
                at: [1, 0],
            });

            // This has to be the top level node because inline nodes cause an empty text node to be placed
            // before and after it. So if you create a link on the first paragraph and press enter it wouldn't
            // let you go to the next line.
            const nodeText = Editor.string(editor, [1]);

            if (match && nodeText === "") {
                Transforms.select(editor, [1, 0]);
                return;
            }
        }

        // This happens here for a reason. For most things it's easier to let the change happen and then
        // modify it accordingly
        insertBreak();

        const [topLevelElement] = Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n),
            mode: "highest",
        });

        const [topLevelNode, topLevelPath] = topLevelElement;

        if (shiftEnterPressed && topLevelNode.type !== "main-heading") {
            // Reset this flag so we don't apply the wrong elements on next line break
            shiftEnterPressed = false;
            return;
        }

        // Now that we know we didn't press shift enter we will take the just rendered content
        // and transform it into it's now type. The following logic will grab the elements
        // from highest to lowest in the tree and our selection should now be at the beginning
        // of the new node so we know the first match will be the be the new node.

        const [newElement] = Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n),
            mode: "lowest",
        });

        const [newElementNode, newElementPath] = newElement;

        Transforms.unsetNodes(editor, ["checked", "placeholder"], {
            at: newElementPath,
            match: (n) => Element.isElement(n),
            mode: "lowest",
        });

        Transforms.unsetNodes(editor, ["color", "backgroundColor", "placeholder", "id"], {
            at: newElementPath,
            match: (n) => Text.isText(n),
            mode: "lowest",
        });

        switch (topLevelNode.type) {
            case "main-heading":
            case "minor-heading":
            case "sub-heading":
                // case "heading-two":
                // case "heading-three":
                Transforms.setNodes(editor, { type: "paragraph" });
                break;
            case "bulleted-list":
            case "numbered-list":
            case "check-list":
                /* We want to determine whether or not the user is trying to finish editing the list or
                sub-list that they are on. To determine this we check if the newly created list item (from
                calling insertBreak) is the last list item in it's parent list. If it is and the previous
                list item (the one the user was on when they pressed enter) is blank than we take that to
                mean they have finished editing the list. If it's a sublist we lift the list item up by one
                level and if its the root list then we turn the list item into a paragraph */
                listItemOnEnter(editor, topLevelNode.type, newElementPath);
                break;
            default:
                break;
        }
    };

    editor.insertData = (data) => {
        const fragment = data.getData("application/x-slate-fragment");

        if (fragment) {
            const decoded = decodeURIComponent(window.atob(fragment));
            const parsed = JSON.parse(decoded);
            editor.insertFragment(parsed);
            return;
        }

        const text = data.getData("text/plain") || "";
        const lines = text.split(/\r\n|\r|\n/).filter((line, i, lines) => {
            return line !== "" || (line === "" && lines[i - 1] !== "");
        });

        // If the range isn't collapsed then we may be pasting in a url to create a link so we pass that along.
        if (!Range.isCollapsed(editor.selection) && lines.length === 1) {
            insertData(data);
            return;
        }

        const bulletListItemPattern = /^\s*\*\s*/;
        const numberedListItemPattern = /^\s*\d\.\s*/;
        const checkListItemUncheckedPattern = /^\s*\[\]\s*/;
        const checkListItemCheckedPattern = /^\s*\[x\]\s*/;

        const [match] =
            Editor.nodes(editor, {
                match: (n) => Element.isElement(n),
                mode: "highest",
            }) || [];

        const [currentNode, currentPath] = match || [];
        let nodeContext = currentNode?.type;
        let index = 0;

        // The list items that are generated inside a list are given an ID. This is because the withIds add-on
        // does not currently detect nested inserts so it will only assign an ID to the top level item (the list).
        // In the future I'll modify it to do so.
        for (let line of lines) {
            if (bulletListItemPattern.test(line)) {
                const text = line.replace(bulletListItemPattern, "");
                let node =
                    nodeContext === "bulleted-list"
                        ? { type: "list-item", children: [{ text: text }] }
                        : {
                              type: "bulleted-list",
                              children: [
                                  { type: "list-item", id: generateEntityId(), children: [{ text: text }] },
                              ],
                          };

                Transforms.insertNodes(editor, node);
                nodeContext = "bulleted-list";
            } else if (numberedListItemPattern.test(line)) {
                const text = line.replace(numberedListItemPattern, "");
                let node =
                    nodeContext === "numbered-list"
                        ? { type: "list-item", children: [{ text: text }] }
                        : {
                              type: "numbered-list",
                              children: [
                                  { type: "list-item", id: generateEntityId(), children: [{ text: text }] },
                              ],
                          };

                Transforms.insertNodes(editor, node);
                nodeContext = "numbered-list";
            } else if (checkListItemUncheckedPattern.test(line)) {
                const text = line.replace(checkListItemUncheckedPattern, "");
                let node =
                    nodeContext === "check-list"
                        ? { type: "check-list-item", children: [{ text: text }] }
                        : {
                              type: "check-list",
                              children: [
                                  {
                                      type: "check-list-item",
                                      id: generateEntityId(),
                                      children: [{ text: text }],
                                  },
                              ],
                          };

                Transforms.insertNodes(editor, node);
                nodeContext = "check-list";
            } else if (checkListItemCheckedPattern.test(line)) {
                const text = line.replace(checkListItemUncheckedPattern, "");
                let node =
                    nodeContext === "check-list"
                        ? { type: "check-list-item", checked: true, children: [{ text: text }] }
                        : {
                              type: "check-list",
                              children: [
                                  {
                                      type: "check-list-item",
                                      id: generateEntityId(),
                                      checked: true,
                                      children: [{ text: text }],
                                  },
                              ],
                          };

                Transforms.insertNodes(editor, node);
                nodeContext = "check-list";
            } else {
                const lineData = new DataTransfer();

                // If there is more than 1 line than we want to be able to escape out of a list. If there is only
                // one line than we want to be able to paste text inline.
                if (
                    (nodeContext === "bulleted-list" ||
                        nodeContext === "numbered-list" ||
                        nodeContext === "check-list") &&
                    lines.length > 1
                ) {
                    void lineData.setData("text/plain", `${line}`);
                    // The following is needed to make sure lists are properly exited. Without this Slate will just
                    // keep appending to the list.
                    const [match] =
                        Editor.nodes(editor, {
                            match: (n) => Element.isElement(n),
                            mode: "highest",
                        }) || [];

                    const [currentNode, currentPath] = match || [];
                    const nextPath = Path.next(currentPath);
                    Transforms.insertNodes(
                        editor,
                        { type: "paragraph", children: [{ text: "" }] },
                        { at: nextPath, select: true }
                    );

                    insertData(lineData);
                } else {
                    // We don't put newlines after the text because that causes new nodes to be created, i.e. the current node
                    // is controlling what the next node should do. This leads to issues when the lines contained a list since we
                    // use insertNodes for those and that if we already had an empty paragraph it would create a new node after the
                    // paragraph instead of turning it into a list (this means we would get an empty line where there shouldn't have been).
                    // By putting the \n at the start it means each line doesn't affect the next - only itself.
                    // We don't put in a new line on the first line since that would insert a line break at the start for no reason.
                    if (index === 0) void lineData.setData("text/plain", `${line}`);
                    else void lineData.setData("text/plain", `\n${line}`);
                    insertData(lineData);
                }

                nodeContext = null;
            }

            index++;
        }
    };

    return editor;
}

function listItemOnEnter(editor, rootNodeType, newListItemPath) {
    const [parentListNode, parentListPath] = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n) && isListElement(n),
        mode: "lowest",
        at: newListItemPath,
    });

    const lastItemPath = [...parentListPath, parentListNode.children.length - 1];

    const isCurrentNodeLastInList = Path.equals(lastItemPath, newListItemPath);
    const previousNodePath = Path.previous(newListItemPath);
    const previousNodeRangeRef = Editor.range(editor, previousNodePath);
    const prevListItemString = Editor.string(editor, previousNodeRangeRef);

    if (isCurrentNodeLastInList && prevListItemString.length === 0) {
        Transforms.liftNodes(editor, { at: newListItemPath });
        if (newListItemPath.length === 2) Transforms.setNodes(editor, { type: "paragraph" });

        // This method is called after a new node has been inserted. This parent list node was
        // grabbed before we lifted up the newly inserted node. So we want to check if the list
        // has more than two children. If it does we want to remove the previous node. This check
        // is here to make sure we don't delete the only node in a list, which will crash Slate.
        if (parentListNode.children.length > 2) {
            Transforms.removeNodes(editor, { at: previousNodePath });
        }
    }
}

function listItemOnDelete(editor, listItemPath) {
    // If the selection isn't just a single point then deleting will delete the content and perhaps the
    // node. This method is only concerned with how nodes are transfor
    const isCollapsed = Range.isCollapsed(editor.selection);
    if (!isCollapsed) return;

    const nodeText = Editor.string(editor, listItemPath);

    const siblingPlacement = listItemPath[listItemPath.length - 1];
    const [parentListElement, parentListPath] = getParentListElement(editor, listItemPath);
    const numChildren = parentListElement.children.length;

    const isFirst = siblingPlacement === 0;
    const isLast = siblingPlacement === numChildren - 1;

    if (nodeText === "" && !isFirst && !isLast) {
        Transforms.removeNodes(editor, { at: listItemPath });
    } else {
        liftListItem(editor, listItemPath);
    }
}

export function withIDs(editor) {
    const { apply } = editor;

    /* We need to keep track of which nodes we remove. We need to do this because of
    slate-history, i.e. the ablity to undo and redo. If I undo a node which deletes it
    and then I redo I expect that created node should have the same ID. Slate doesn't 
    really have a remove_node operation, it can remove text and merge nodes. As such we keep
    track of which IDs are removed when merging, and then when splitting or inserting a node if
    the ID matches a removed ID then we allow it, otherwise we create a new ID. */

    editor._removedIDs = new Set();

    editor.apply = (operation) => {
        if (!operation.path) return apply(operation);
        const newUUID = generateEntityId();

        // insert node will happen on redos and operations where one node will turn into two, for example
        // turning a paragraph into a list, split node happens on pressing enter, merge node will happen
        // when deleting paragraphs, and remove nodes will happen when deleting empty list items that
        // aren't first or last (by my doing - probably could have just merged them as well)
        if (operation.type === "insert_node") {
            let idToUse = newUUID;

            if (editor._removedIDs.has(operation.node?.id)) {
                idToUse = operation.node.id;
                editor._removedIDs.delete(idToUse);
            }

            return apply({
                ...operation,
                node: { ...operation.node, id: idToUse },
            });
        } else if (operation.type === "split_node" && operation.properties.type) {
            let idToUse = newUUID;

            if (editor._removedIDs.has(operation.properties?.id)) {
                idToUse = operation.properties.id;
                editor._removedIDs.delete(idToUse);
            }

            return apply({
                ...operation,
                properties: { ...operation.properties, id: idToUse },
            });
        } else if (operation.type === "merge_node" && operation.node?.id) {
            editor._removedIDs.add(operation.properties?.id);

            // "merge_node" doesn't need an ID property since it isn't creating a node that will inherit
            // the ID
            return apply(operation);
        } else if (operation.type === "remove_node" && operation.node?.id) {
            editor._removedIDs.add(operation.node?.id);

            return apply(operation);
        } else {
            return apply(operation);
        }
    };
    return editor;
}

export function withShortcuts(editor) {
    const { deleteBackward, insertText } = editor;

    editor.insertText = (text) => {
        const { selection } = editor;

        /* We apply a transform if we find that a space has been entered and the text before the space
        in that same block matches one of our shortcuts. The anchor path check is so we don't apply any
        transformations to the main heading */
        if (text === " " && !isMainHeadingSelected(editor) && Range.isCollapsed(selection)) {
            const { anchor } = selection;

            const nodeText = getTextPreceedingCursorInTextNode(editor);
            const type = getShortcutTextType(nodeText);

            if (type) {
                const effectProps = getShortcutTextEffects(nodeText);
                // Delete the shortcut text
                Editor.deleteBackward(editor, { unit: "word" });

                toggleBlock(editor, type, effectProps);

                return;
            }
        }
        insertText(text);
    };

    editor.deleteBackward = (...args) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const match = Editor.above(editor, {
                match: (n) => Editor.isBlock(editor, n),
            });

            if (match) {
                const [block, path] = match;
                const start = Editor.start(editor, path);
                const nodeText = Editor.string(editor, path);

                /* 
                In the case that the cursor is at the beginning of the second node which is an
                empty paragraph and the first node is a main heading we need to handle that seperately. 
                By default deleteBackward uses Transforms.mergeNodes which has a bug that turns the 
                main heading into a paragraph. 
                
                The selection.anchor.path check is because you can have an Element (e.g. paragraph Node) that
                has more than one child (two or more text nodes). If the cursor is at the start of the second text
                node and you don't have this check than it will stop deleting a character. This took me ages to debug.

                You run into this multiple text node issue because of the ids we place on nodes. When normalizing nodes Slate seems to
                normalise one step at a time. So if you have two adjacent paragraph elements it will merge those two level elements first.
                So you will for a moment have one paragraph element with two text nodes. Then when specifically deciding to merge nodes
                it will check the two adjacent text nodes to see if both the text and all its properties are equal. Usually this is the 
                case and these nodes are happily merged. But when you add ids now the properties of these text nodes will differ so 
                they won’t be merged. So you are now stuck with a paragraph element with two text nodes.

                */
                if (
                    block.type === "paragraph" &&
                    path[0] === 1 &&
                    selection.anchor.path[1] === 0 &&
                    editor.selection?.anchor.offset === 0
                ) {
                    const [mainHeadingMatch] = Editor.nodes(editor, {
                        at: [0, 0],
                        match: (n) => n.type === "main-heading",
                    });

                    if (mainHeadingMatch) {
                        if (nodeText) return;

                        Transforms.select(editor, [0, 0]);
                        Transforms.removeNodes(editor, { at: [1] });
                        return;
                    }
                }

                // TODO: If then last key that was pressed was shift + enter (so they created a new
                // line with same styling) then don't turn it back into a paragraph, instead let
                // deleteBackward do it's thing and marge the nodes.
                if (
                    block.type !== "paragraph" &&
                    block.type !== "main-heading" &&
                    Point.equals(selection.anchor, start)
                ) {
                    if (isListItemElement(block)) {
                        listItemOnDelete(editor, path);
                    } else {
                        Transforms.setNodes(editor, { type: "paragraph" });
                    }

                    return;
                }
            }

            deleteBackward(...args);
        }
    };

    return editor;
}

/**
 * Normalises list types so our DOM tree is semantically correct and we can correctly enforce rules like
 * only allowing a list item to be indented if it is not the only child of the list. Without merging adjacent
 * lists we might have two adjacent lists with one element that visually appears to be one list with two
 * elements. It would be confusing if you couldn't indent a list item in this case. This does not actually
 * enable nested lists, that functionality is handled in the onKeyDown handler of the editor
 * @param {Editor} editor The Editor whose state you wish to check
 */
export function withNestedLists(editor) {
    const { normalizeNode, insertText } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Text.isText(node) || (Element.isElement(node) && node.children.length === 0)) return;

        let n = 0;

        for (let i = 0; i < node.children.length; i++, n++) {
            const child = node.children[i];
            const prev = node.children[i - 1];

            if (prev != null && isListElement(prev) && isListElement(child) && prev.type === child.type) {
                Transforms.mergeNodes(editor, { at: path.concat(n), voids: true });
                n--;
            }

            if (isListItemElement(child) && !isValidListItemForParent(child, node)) {
                const newType = node.type === "check-list" ? "check-list-item" : "list-item";
                Transforms.setNodes(editor, { type: newType }, { at: path.concat(n) });
            }
        }

        normalizeNode(entry);

        // TODO: Fix internally in Slate and remove. This shouldn't be necessary if the internal Slate when doing
        // merges is fixed (it can lift list item nodes out of their parent in specific scenarios). This is also
        // an imperfect approximation since we don't have enough information to determine if it should be a bulleted
        // list or numbered list. I chose bulleted because it is more common
        // DOESN'T WORK QUITE RIGHT - DOUBLE WRAPS NEW LIST ITEMS
        // if (isListItemElement(node)) {
        //     const [parentNode] = Editor.parent(editor, path);

        //     if (!isListElement(parentNode)) {
        //         const parentType = node.type === "check-list-item" ? "check-list" : "bulleted-list";
        //         Transforms.wrapNodes(editor, { type: parentType }, { at: path });
        //     }
        // }
    };

    return editor;
}

function getTextPreceedingCursorInTextNode(editor, selection) {
    const currentSelection = editor.selection || selection;
    const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
        at: editor.selection,
    });
    const path = block ? block[1] : [];
    const start = Editor.start(editor, path);
    const range = { anchor: currentSelection.anchor, focus: start };
    const nodeText = Editor.string(editor, range);

    return nodeText;
}

function getShortcutTextType(nodeText) {
    const type = MD_SHORTCUTS[nodeText];

    return type;
}

function getShortcutTextEffects(nodeText) {
    const effect = EFFECTS[nodeText];
    const effectProps = effect ? { [effect]: true } : {};

    return effectProps;
}
