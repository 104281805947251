import React, { useCallback, useMemo, useState, useEffect, useRef, forwardRef } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate, ReactEditor } from "slate-react";
import { Editor, Transforms, createEditor, Text, Path, Node, Element as SlateElement } from "slate";
import { withHistory } from "slate-history";
import { AnimatePresence } from "framer-motion";
//import { useCollaborativeEditor } from "slate-collaborative-plugin";

import {
    withShortcuts,
    withSmartFormatting,
    withCode,
    withIDs,
    withNestedLists,
    withUrlLinks,
    withInsertCommand,
    withModes,
    withInternalLinks,
    withMetadata,
} from "./add-ons";

import { Element } from "./Element/Element";
import { Leaf } from "./Leaf/Leaf";
import { Toolbar } from "./Toolbar/Toolbar";
import { CommandPopover } from "./CommandPopover/CommandPopover";
import { LinkerPopover } from "./LinkerPopover/LinkerPopover";
import { upsertAuthorNode, removeAuthorNode } from "@modules/tablet-helpers";

import {
    toggleMark,
    canLiftListItem,
    isListItemElement,
    liftListItem,
    nestListItem,
    canNestListItem,
    getBoundedPreviousPath,
    getBoundedNextPath,
} from "./helpers";
import { throttle, pipe } from "@modules/helpers";
import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";
import { usePrevious } from "@hooks/general-hooks";
import { EditorContext } from "@context/editor";

import "./ScribeEditor.scss";

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
    "mod+k": "link",
};

// Playground mode will turn off all the features that require network requests since this can only
// be made if we wrap the page with apollo. We can't do this for the landing page because we want it to
// be statically rendered at build time.
const ScribeEditor = React.memo(
    forwardRef(
        (
            { editMode, autoFocus, value = [], onChange, onFocus, onBlur, playgroundMode = false },
            forwardedRef
        ) => {
            const renderElement = useCallback((props) => <Element {...props} />, []);
            const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
            const editor = useMemo(
                () =>
                    pipe(
                        withReact,
                        withHistory,
                        withModes,
                        withMetadata,
                        withInsertCommand,
                        withInternalLinks,
                        withUrlLinks,
                        withShortcuts,
                        withSmartFormatting,
                        withCode,
                        withNestedLists,
                        withIDs
                    )(createEditor()),
                []
            );

            // const {
            //     ready,
            //     value: collaborationValue,
            //     selection,
            //     onChange: onChangeCollaboration,
            // } = useCollaborativeEditor({
            //     editor,
            //     collaborativeServiceURL: "wss://i4qs5zxshk.execute-api.us-east-1.amazonaws.com/PROD",
            //     documentId: `scribe/pages/1234@v1`,
            //     initialValue: value,
            // });

            function handleChange(value) {
                //onChangeCollaboration(arguments);
                if (onChange) onChange(value);
            }

            const [isEditorFocused, setIsEditorFocused] = useState(false);
            const [isTyping, setIsTyping] = useState(false);

            useEffect(() => {
                if (autoFocus && editMode) {
                    ReactEditor.focus(editor);
                    Transforms.select(editor, {
                        path: [0, 0],
                        offset: 0,
                    });
                }
            }, [editMode, autoFocus]);

            useEffect(() => {
                if (value.length === 0) Transforms.deselect(editor);
            }, [value]);

            useEffect(() => {
                function handleClick(event) {
                    if (!event?.target.closest || event.target.closest("[data-scribe-editor")) {
                        setIsEditorFocused(true);
                    } else setIsEditorFocused(false);
                }

                document.addEventListener("click", handleClick);
                return () => document.removeEventListener("click", handleClick);
            }, []);

            useEffect(() => {
                if (!isEditorFocused) return;

                function handleType(event) {
                    setIsTyping(true);
                }

                function handleMouseMove(event) {
                    setIsTyping(false);
                }

                document.addEventListener("keydown", handleType);
                document.addEventListener("mousemove", handleMouseMove);

                return () => {
                    document.removeEventListener("keydown", handleType);
                    document.removeEventListener("mousemove", handleMouseMove);
                };
            }, [isEditorFocused]);

            return (
                <EditorContext.Provider value={{ isTyping }}>
                    <div
                        data-scribe-editor
                        data-edit-mode={editMode}
                        ref={forwardedRef}
                        data-is-editing={isEditorFocused}
                        data-is-typing={isTyping}
                    >
                        <AnimatePresence>
                            <Slate
                                editor={editor}
                                value={value}
                                //value={collaborationValue}
                                onChange={handleChange}
                                //selection={selection}
                            >
                                <Toolbar />
                                <CommandPopover />
                                {!playgroundMode && <LinkerPopover />}
                                <Editable
                                    //readOnly={!editMode || !ready}
                                    readOnly={!editMode}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                    spellCheck
                                    autoFocus={value.length && autoFocus}
                                    as="article"
                                    onKeyDown={(event) => {
                                        for (const hotkey in HOTKEYS) {
                                            if (isHotkey(hotkey, event)) {
                                                event.preventDefault();
                                                const mark = HOTKEYS[hotkey];

                                                if (mark === "link") {
                                                    // Currently handled in the toolbar itself
                                                } else {
                                                    toggleMark(editor, mark);
                                                }
                                            }
                                        }

                                        switch (event.key) {
                                            case "Tab":
                                                event.preventDefault();

                                                const [topLevelBlock] = Editor.above(editor, {
                                                    match: (n) => Editor.isBlock(editor, n),
                                                    mode: "highest",
                                                });

                                                const listItemNodeEntry = Editor.above(editor, {
                                                    match: (n) =>
                                                        Editor.isBlock(editor, n) && isListItemElement(n),
                                                });

                                                // TODO: Enable tabbing to work with multiple items. When you have a range Editor.above will
                                                // use it's common path, which when spanning over multiple list items will be there parent list.
                                                // That's why we do the destructure check because all their ancestors will be lists, not list items.
                                                // This will be hard to get right so we can come back to this later.
                                                if (!listItemNodeEntry) break;
                                                const [listItemNode, listItemPath] = listItemNodeEntry;
                                                if (!listItemNode) break;

                                                if (event.shiftKey) {
                                                    liftListItem(
                                                        editor,
                                                        Path.parent(editor.selection.anchor.path)
                                                    );
                                                } else {
                                                    if (!canNestListItem(editor, listItemPath)) break;

                                                    nestListItem(editor, listItemPath, topLevelBlock.type);
                                                }
                                                break;
                                            case "ArrowUp":
                                                if (event.altKey) {
                                                    event.preventDefault();

                                                    const currentElementPath = Path.parent(
                                                        editor.selection.anchor.path
                                                    );
                                                    const previousElementPath = getBoundedPreviousPath(
                                                        editor,
                                                        currentElementPath
                                                    );

                                                    Transforms.moveNodes(editor, {
                                                        match: (n) => SlateElement.isElement(n),
                                                        mode: "lowest",
                                                        to: previousElementPath,
                                                    });
                                                }
                                                break;

                                            case "ArrowDown":
                                                if (event.altKey) {
                                                    event.preventDefault();

                                                    const currentElementPath = Path.parent(
                                                        editor.selection.anchor.path
                                                    );
                                                    const nextElementPath = getBoundedNextPath(
                                                        editor,
                                                        currentElementPath
                                                    );
                                                    Transforms.moveNodes(editor, {
                                                        match: (n) => SlateElement.isElement(n),
                                                        mode: "lowest",
                                                        to: nextElementPath,
                                                    });
                                                }
                                                break;
                                        }
                                    }}
                                    onDragOver={throttle((event) => {
                                        const node = ReactEditor.toSlateNode(editor, event.target);
                                        const path = ReactEditor.findPath(editor, node);
                                    }, 500)}
                                    onFocus={() => {
                                        if (onFocus) onFocus();
                                    }}
                                    onBlur={() => {
                                        if (onBlur) onBlur();
                                    }}
                                />
                            </Slate>
                        </AnimatePresence>
                    </div>
                </EditorContext.Provider>
            );
        }
    )
);

export default ScribeEditor;
