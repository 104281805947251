import React from "react";

import Popover, { getPositioner } from "@components/Popover/Popover";

import "./ToolbarPopover.scss";

const ToolbarPopover = ({ show, onClose, pinTo, children }) => {
    return (
        <Popover
            targetRef={pinTo}
            position={getPositioner({ bindToViewport: true, offsetY: 8, yPreference: "bottom" })}
            show={show}
            focusable={false}
            onClose={onClose}
            preventOutsideInteraction
        >
            {children}
        </Popover>
    );
};

export { ToolbarPopover };
