import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSlate, ReactEditor } from "slate-react";
import { Editor, Range, Text } from "slate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useMutation, useQuery, gql } from "@apollo/client";

import Popover, { getPositioner } from "@components/Popover/Popover";
import SubScript from "@components/SubScript/SubScript";
import Loader from "@components/Loader/Loader";

import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";
import {
    useStartPoint,
    useTextFromPoint,
    useResponsiveSelectionRect,
    useUnfocusedListControls,
    useOnSelectionPathChange,
} from "@hooks/editor-hooks";
import { useSearch } from "@hooks/search-hooks";
import { usePage } from "@hooks/page-hooks";
import { useCreateNewPage } from "@hooks/tablet-hooks";
import { generateEntityId } from "@modules/helpers";

import { MODES } from "../add-ons";

import "./LinkerPopover.scss";

const CONNECT_PAGES = gql`
    mutation connectTablets($input: ConnectTabletsInput!) {
        connectTablets(input: $input) {
            id

            connectedTablets {
                id
                latest {
                    id
                    title
                }
            }
        }
    }
`;

const DISCONNECT_PAGES = gql`
    mutation disconnectTablets($input: DisconnectTabletsInput!) {
        disconnectTablets(input: $input) {
            id

            connectedTablets {
                id
                latest {
                    id
                    title
                }
            }
        }
    }
`;

const LinkerPopover = React.memo(() => {
    const editor = useSlate();
    const searchIndex = useSearch();
    const page = usePage();
    const [createNewPage, { loading: pageCreationInProgress }] = useCreateNewPage();
    const [connectPages] = useMutation(CONNECT_PAGES);
    const [disconnectPages] = useMutation(DISCONNECT_PAGES);

    const [show, setShow] = useState(false);
    const tabletListRef = useRef();
    const selectionRect = useResponsiveSelectionRect(show);
    const [highlightedValue, setHighlightedValue] = useState("");
    const [noResultsText, setNoResultsText] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const startPoint = useStartPoint("mode", (mode) => mode === MODES.LINK);
    const currentNodeText = useTextFromPoint(startPoint);
    const hasMatchingOptions = searchResults.length > 0;
    const numItems = searchResults.length;

    useOnSelectionPathChange(show, editor.selection, closePopover);

    useEffect(() => {
        if (!searchIndex || !page) return;

        async function searchForTablets() {
            const searchResults = await searchIndex.search(currentNodeText, {
                restrictSearchableAttributes: ["title"],
            });

            const exactSearchResults = searchResults?.hits.filter(
                ({ id, title }) =>
                    title?.toLowerCase().includes(currentNodeText?.toLowerCase()) && id !== page.id
            );
            setSearchResults(exactSearchResults);

            if (exactSearchResults?.length !== 0 && !highlightedValue) {
                setHighlightedValue(exactSearchResults[0].id);
            }
        }

        searchForTablets();
    }, [searchIndex, currentNodeText, page]);

    function closePopover() {
        editor.changeModeToDefault();
        setShow(false);
        setHighlightedValue(null);
        setNoResultsText("");
    }

    useEffect(() => {
        const { selection } = editor;

        if (!selection || !ReactEditor.isFocused(editor) || !Range.isCollapsed(selection)) {
            closePopover();
        }
    });

    useEffect(() => {
        const shouldShow = editor.mode === MODES.LINK && !!editor.selection;

        setShow(shouldShow);
    }, [editor.mode]);

    useUnfocusedListControls({
        show,
        highlightedValue: highlightedValue || currentNodeText,
        listRef: tabletListRef,
        optionSelector: (tabletListEl) => {
            const optionEls = tabletListEl.querySelectorAll(".tablet-list-item");
            const options = [...optionEls].map(({ dataset }) => dataset.id);

            return options;
        },
        optionElementSelector: (tabletListEl) => {
            const optionEls = tabletListEl.querySelectorAll(".tablet-list-item");
            const optionEl = [...optionEls].find(({ dataset }) => dataset.id === highlightedValue);

            return optionEl;
        },
        onChangeHighlight: (nextOptionValue) => setHighlightedValue(nextOptionValue),
        onClose: () => {
            editor.changeModeToDefault();
            setShow(false);
        },
        onSelect: (highlightedValue) => handleSelect(highlightedValue),
        deps: [hasMatchingOptions, currentNodeText, page],
    });

    async function handleSelect(pageId) {
        if (hasMatchingOptions) {
            const pageTitle = searchResults.find(({ id }) => id == pageId).title;

            editor.changeModeToDefault();
            editor.createInternalLink(pageId, pageTitle, (node) => {
                disconnectPages({
                    variables: {
                        input: {
                            tabletsToDisconnect: [page.id],
                            tabletsToDisconnectFrom: [node.pageId],
                        },
                    },
                });
            });
            await connectPages({
                variables: { input: { tabletsToConnect: [page.id], tabletsToConnectTo: [pageId] } },
            });
        } else {
            const pageUUID = generateEntityId();
            const formattedTitle =
                currentNodeText.slice(-2) === "]]" ? currentNodeText.slice(0, -2) : currentNodeText;

            await createNewPage(
                { uuid: pageUUID, connectedTablets: [page.id], title: formattedTitle },
                false
            );

            editor.changeModeToDefault();
            editor.createInternalLink(pageUUID, formattedTitle);
        }

        setHighlightedValue(null);
        setShow(false);
    }

    useIsomorphicLayoutEffect(() => {
        if (!currentNodeText || !tabletListRef.current) return;

        if (hasMatchingOptions) {
            const optionEls = tabletListRef.current.querySelectorAll(".tablet-list-item");
            const options = [...optionEls].map(({ dataset }) => dataset.id);

            setHighlightedValue(options[0]);
        } else if (!noResultsText) {
            setNoResultsText(currentNodeText);
        }
    }, [currentNodeText]);

    return (
        <Popover
            className="linker-popover"
            targetRect={selectionRect}
            position={getPositioner({ bindToViewport: true, offsetY: 8, yPreference: "largest" })}
            positionDeps={[numItems, currentNodeText]}
            show={show}
            ref={tabletListRef}
            focusable={false}
            onClose={closePopover}
        >
            <div className="linker-popover-content" data-num-options={searchResults?.length}>
                {!hasMatchingOptions && (
                    <div className="create-new-page-prompt">
                        {pageCreationInProgress && <Loader theme="light" />}
                        {!pageCreationInProgress && (
                            <>
                                <p className="no-results-text">Press Enter to create new page</p>
                                <FontAwesomeIcon icon={faAngleDown} className="arrow-down" />
                            </>
                        )}
                    </div>
                )}
                {hasMatchingOptions && (
                    <div>
                        <h4 className="tablet-list-heading">Matching Pages</h4>
                        <ul className="tablet-list">
                            {searchResults.map(({ id, title }) => (
                                <li
                                    className="tablet-list-item"
                                    data-id={id}
                                    key={id}
                                    onClick={() => handleSelect(id)}
                                    data-highlighted={highlightedValue === id}
                                >
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </Popover>
    );
});

export { LinkerPopover };
