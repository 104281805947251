import React, { useRef, useState, useEffect, useContext } from "react";
import { notificationContext } from "@context/notifications";

/**
 * Alerts the component to any mouse movements whilst we are actively listening (by
 * setting listen to true)
 * @param {boolean} listen When false we don't listen for mouse movements
 */
export function useMouseMoved(listen = false) {
    const [mouseMoved, setMouseMoved] = useState();

    useEffect(() => {
        if (!listen || mouseMoved) return;

        function handleMouseMove(event) {
            setMouseMoved(true);
        }

        document.addEventListener("mousemove", handleMouseMove);
        return () => document.removeEventListener("mousemove", handleMouseMove);
    }, [listen, mouseMoved]);

    useEffect(() => {
        if (!listen) setMouseMoved(false);
    }, [listen]);

    return mouseMoved;
}

/**
 * Allows us to detect if the current tab/page is focused (i.e. the user is actively using Scribe) or
 * visible (Scribe is on the open tab in a non-minimized browser)
 */
export function usePageStatus() {
    const [focused, setFocused] = useState(true);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Set the name of the hidden property and the change event for visibility
        let hidden, visibilityChange;

        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        function handleFocus() {
            setFocused(true);
        }

        function handleBlur() {
            setFocused(false);
        }

        function handleVisibilityChange() {
            setVisible(!document[hidden]);
        }

        document.addEventListener("focus", handleFocus);
        document.addEventListener("blur", handleBlur);
        document.addEventListener(visibilityChange, handleVisibilityChange);

        return () => {
            document.removeEventListener("focus", handleFocus);
            document.removeEventListener("blur", handleBlur);
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        };
    }, []);

    return { focused, visible };
}
