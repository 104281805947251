import React from "react";
import "./SubScript.scss";

const SubScript = ({ children, subscript }) => {
    return (
        <span className="sub-script" data-subscript={subscript}>
            {children}
        </span>
    );
};

export default SubScript;
