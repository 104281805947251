/**
 * TODO: I have no clue how to do this but when clicking outside the popover the result of useTextFromPoint
 */

import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSlate, ReactEditor } from "slate-react";
import { Editor, Range, Text, Transforms } from "slate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/pro-regular-svg-icons";

import Popover, { getPositioner } from "@components/Popover/Popover";
import SubScript from "@components/SubScript/SubScript";

import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";
import { useId } from "@hooks/general-hooks";
import { useMouseMoved } from "@hooks/event-hooks";
import {
    useStartPoint,
    useTextFromPoint,
    useResponsiveSelectionRect,
    useUnfocusedListControls,
    useOnSelectionPathChange,
} from "@hooks/editor-hooks";
import { makeHash, throttle, debounce } from "@modules/helpers";
import { MODES } from "../add-ons";

import "./CommandPopover.scss";

const COMMAND_MAP = {
    "internal-link": "Link to other note/s",
    "block-reference": "Embed text from another note",
    "strike-through": "Strike-through",
    "minor-heading": "Heading",
    "sub-heading": "Small Heading",
    "bulleted-list": "Bulleted List",
    "numbered-list": "Numbered List",
    "check-list": "Check List",
    code: "Code",
    paragraph: "Text",
    bold: "Bold",
    italic: "Italic",
};

const INITIAL_HIGHLIGHTED_VALUE = "internal-link";

const SEARCH_MAP = {
    basic: {
        "internal-link": ["link to other notes"],
    },
    blocks: {
        "minor-heading": ["heading"],
        "sub-heading": ["small heading", "sub-heading", "sub heading"],
        "bulleted-list": ["bulleted list"],
        "numbered-list": ["numbered list"],
        "check-list": ["check list", "todo"],
        paragraph: ["paragraph", "text"],
        code: ["code"],
    },
};

function shouldShow(id, text) {
    let searchTerms;

    for (const [group, members] of Object.entries(SEARCH_MAP)) {
        let isMember = false;

        for (const [member, membersSearchTerms] of Object.entries(members)) {
            if (member === id) {
                isMember = true;
                searchTerms = membersSearchTerms;
                break;
            }
        }

        if (group === id) {
            searchTerms = Object.values(members).flat();
            break;
        } else if (isMember) break;
    }

    return searchTerms.some((searchTerm) => searchTerm.includes(text));
}

function shouldShowAny(text) {
    for (const members of Object.values(SEARCH_MAP)) {
        for (const membersSearchTerms of Object.values(members)) {
            if (membersSearchTerms.some((searchTerm) => searchTerm.includes(text))) return true;
        }
    }
    return false;
}

function getNumOptionsDisplayed(text) {
    let numOptions = 0;

    for (const members of Object.values(SEARCH_MAP)) {
        for (const membersSearchTerms of Object.values(members)) {
            if (membersSearchTerms.some((searchTerm) => searchTerm.includes(text))) numOptions++;
        }
    }

    return numOptions;
}

const CommandPopover = (props) => {
    const editor = useSlate();
    const id = useId();
    const [show, setShow] = useState(false);
    const commandListRef = useRef();
    const selectionRect = useResponsiveSelectionRect(show);
    const [highlightedValue, setHighlightedValue] = useState(INITIAL_HIGHLIGHTED_VALUE);
    const [noResultsText, setNoResultsText] = useState("");
    const mouseMoved = useMouseMoved(show);
    const selectionRef = useRef();

    const startPoint = useStartPoint("mode", (mode) => mode === MODES.COMMAND);
    const currentNodeText = useTextFromPoint(startPoint);
    const hasMatchingOptions = shouldShowAny(currentNodeText);
    const numItems = getNumOptionsDisplayed(currentNodeText);

    useOnSelectionPathChange(show, editor.selection, closePopover);

    function closePopover() {
        editor.changeModeToDefault();
        setShow(false);
        setHighlightedValue(INITIAL_HIGHLIGHTED_VALUE);
        setNoResultsText("");
    }

    useEffect(() => {
        const { selection } = editor;

        if (selection) selectionRef.current = selection;
    });

    useEffect(() => {
        const { selection } = editor;

        if (!selection || !ReactEditor.isFocused(editor) || !Range.isCollapsed(selection)) {
            closePopover();
        }
    });

    useEffect(() => {
        const shouldShow = editor.mode === MODES.COMMAND && !!editor.selection;
        setShow(shouldShow);
    }, [editor.mode]);

    useUnfocusedListControls({
        show,
        highlightedValue,
        listRef: commandListRef,
        optionSelector: (commandListEl) => {
            const optionEls = commandListEl.querySelectorAll(".command-list-item");
            const options = [...optionEls].map(({ dataset }) => dataset.value);

            return options;
        },
        optionElementSelector: (commandListEl) => {
            const optionEls = commandListEl.querySelectorAll(".command-list-item");
            const optionEl = [...optionEls].find(({ dataset }) => dataset.value === highlightedValue);

            return optionEl;
        },
        onChangeHighlight: (nextOptionValue) => setHighlightedValue(nextOptionValue),
        onSelect: (highlightedValue) => handleSelect(highlightedValue),
    });

    function handleSelect(value) {
        ReactEditor.focus(editor);
        Transforms.setSelection(editor, selectionRef.current);
        // The order here is important, inserting a command can change the editors mode (e.g. to link)
        // and we don't want to override that.
        editor.changeModeToDefault();
        editor.insertCommand(value);
        setHighlightedValue(INITIAL_HIGHLIGHTED_VALUE);
        setShow(false);
    }

    useIsomorphicLayoutEffect(() => {
        if (!currentNodeText || !commandListRef.current) return;

        if (hasMatchingOptions) {
            const optionEls = commandListRef.current.querySelectorAll(".command-list-item");
            const options = [...optionEls].map(({ dataset }) => dataset.value);

            setHighlightedValue(options[0]);
        } else if (!noResultsText) {
            setNoResultsText(currentNodeText);
        } else if (currentNodeText.length >= noResultsText.length + 3) {
            closePopover();
        }
    }, [currentNodeText]);

    return (
        <Popover
            className="command-popover"
            targetRect={selectionRect}
            position={getPositioner({ bindToViewport: true, offsetY: 8, yPreference: "largest" })}
            positionDeps={[numItems, currentNodeText]}
            show={show}
            ref={commandListRef}
            focusable={false}
            onClose={closePopover}
            data-mouse-moved={mouseMoved}
        >
            <div className="command-popover-content">
                {!hasMatchingOptions && <p className="no-results-text">No results</p>}

                {shouldShow("basic", currentNodeText) && (
                    <div role="group" aria-labelledby={makeHash("basic", id)} className="command-section">
                        <h4 id={makeHash("basic", id)} className="command-popover-heading">
                            Basic
                        </h4>
                        <ul className="command-list">
                            {shouldShow("internal-link", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "internal-link"}
                                    value="internal-link"
                                    ariaLabel="Turn text into heading"
                                >
                                    <div className="rounded-border">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 16 16"
                                            enableBackground="new 0 0 16 16"
                                            xmlSpace="preserve"
                                            className="internal-link-icon"
                                            alt="Link to other notes"
                                        >
                                            <g id="git_new_branch">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M14,2h-1V1c0-0.55-0.45-1-1-1s-1,0.45-1,1v1h-1C9.45,2,9,2.45,9,3
			c0,0.55,0.45,1,1,1h1v1c0,0.55,0.45,1,1,1s1-0.45,1-1V4h1c0.55,0,1-0.45,1-1C15,2.45,14.55,2,14,2z M10.82,6.8
			C10.51,7.51,9.82,8,9,8H7C6.27,8,5.59,8.2,5,8.55V5.82C6.16,5.4,7,4.3,7,3c0-1.66-1.34-3-3-3S1,1.34,1,3c0,1.3,0.84,2.4,2,2.82
			v4.37C1.84,10.59,1,11.7,1,13c0,1.66,1.34,3,3,3s3-1.34,3-3c0-1.04-0.53-1.95-1.32-2.49C6.03,10.2,6.49,10,7,10h2
			c1.9,0,3.49-1.33,3.89-3.11C12.6,6.96,12.31,7,12,7C11.59,7,11.2,6.92,10.82,6.8z M4,2c0.55,0,1,0.45,1,1S4.55,4,4,4S3,3.55,3,3
			S3.45,2,4,2z M4,14c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S4.55,14,4,14z"
                                                />
                                            </g>
                                        </svg>
                                    </div>

                                    <span>{COMMAND_MAP["internal-link"]}</span>
                                </CommandListItem>
                            )}
                        </ul>
                    </div>
                )}
                {shouldShow("blocks", currentNodeText) && (
                    <div role="group" aria-labelledby={makeHash("blocks", id)} className="command-section">
                        <h4 id={makeHash("blocks", id)} className="command-popover-heading">
                            Turn into
                        </h4>
                        <ul className="command-list">
                            {shouldShow("minor-heading", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "minor-heading"}
                                    value="minor-heading"
                                    ariaLabel="Turn text into a heading"
                                >
                                    <div className="rounded-border">
                                        <SubScript subscript="1">H</SubScript>
                                    </div>

                                    <span>{COMMAND_MAP["minor-heading"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("sub-heading", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "sub-heading"}
                                    value="sub-heading"
                                    ariaLabel="Turn text into a small heading"
                                >
                                    <div className="rounded-border">
                                        <SubScript subscript="2">H</SubScript>
                                    </div>

                                    <span>{COMMAND_MAP["sub-heading"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("bulleted-list", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "bulleted-list"}
                                    value="bulleted-list"
                                    ariaLabel="Turn text into a bulleted list"
                                >
                                    <div className="rounded-border">•</div>

                                    <span>{COMMAND_MAP["bulleted-list"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("numbered-list", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "numbered-list"}
                                    value="numbered-list"
                                    ariaLabel="Turn text into a numbered list"
                                >
                                    <div className="rounded-border">1.</div>

                                    <span>{COMMAND_MAP["numbered-list"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("check-list", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "check-list"}
                                    value="check-list"
                                    ariaLabel="Turn text into a check list"
                                >
                                    <div className="rounded-border">✓</div>

                                    <span>{COMMAND_MAP["check-list"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("code", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "code"}
                                    value="code"
                                    ariaLabel="Create a code block"
                                >
                                    <div className="rounded-border classic-text">
                                        <FontAwesomeIcon icon={faCode} />
                                    </div>

                                    <span>{COMMAND_MAP["code"]}</span>
                                </CommandListItem>
                            )}
                            {shouldShow("paragraph", currentNodeText) && (
                                <CommandListItem
                                    onSelect={(value) => handleSelect(value)}
                                    highlighted={highlightedValue === "paragraph"}
                                    value="paragraph"
                                    ariaLabel="Turn text into a paragraph"
                                >
                                    <div className="rounded-border classic-text">T</div>

                                    <span>{COMMAND_MAP["paragraph"]}</span>
                                </CommandListItem>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </Popover>
    );
};

const CommandListItem = ({ children, value, highlighted = false, onSelect, ariaLabel }) => {
    return (
        <li
            className="command-list-item"
            role="option"
            aria-label={ariaLabel}
            aria-selected={highlighted}
            data-highlighted={highlighted}
            data-value={value}
            onClick={() => onSelect(value)}
            tabIndex={-1}
        >
            {children}
        </li>
    );
};

export { CommandPopover };
